export default {
  lang: 'Việt nam(越南文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: 'Meron',
      wala: 'Wala',
      draw: 'DRAW',
      draw2: 'DRAW',
      cancel: 'Hủy bỏ',
      red: 'Đỏ',
      blue: 'Xanh',
      gold: 'Vàng',
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: 'Xác nhận thực hiện',
      no: 'Hủy bỏ thực hiện',
      go: 'Tiếp',
      search: 'Tìm kiếm',
      reset: 'Làm mới',
      create: 'Tạo',
      logout: 'Đăng xuất',
      submit: 'Xác nhận gửi đi',
      edit: 'Chỉnh sửa',
      // risk_edit: '',
      lock_earning: 'Thanh toán',
      decision_on_hold: 'Tạm dừng trận đấu',
      cancel_on_hold: 'Trận đấu bắt đầu',
      not_allow_bet: 'Không được phép cược',
      allow_bet: 'Được phép cược',
      next: 'Bước tiếp theo',
      prev: 'Bước trước đó',
      step: 'Bước',
      ok: 'Xác nhận',
      collapse: 'Tập hợp',
      mask_video: 'Mặt nạ video',
      cancel_mask_video: 'Đóng mặt nạ video',
      records: 'Lịch sử',
      cancel: 'Hủy bỏ',
      export: 'Xuất khẩu',
      select_file: 'Chọn tập tin',
      upload_image: 'Tải lên hình ảnh',
      cancel_upload: 'Hủy tải lên',
      // update: '',
      // update_all: "",
      // upload_all_images: "",
      // cancel_update_all: "",
      refresh_fights: 'Làm mới mã trận đấu',
      open_bet: 'Chấp nhận đặt cược',
      close_bet: 'Đóng đặt cược',
      re_declare: 'Công bố bên chiến thắng',
      locking: 'Đang chia thưởng',
      open_arena: 'Bắt đầu trận đấu',
      close_arena: 'Đóng tất cả các vòng dưới Pool',
      // refresh_cache: '',
      // cancel_all: '',
      delete: 'Xóa',
      monitor: 'Giám sát',
      // monitor_video: '',
      // retry: '',
      // refresh: '',
    },
    common: {
      account: 'Tài khoản',
      account_id: 'Số hiệu',
      active_status_list: {
        all: 'Toàn bộ',
        active: 'Mở',
        close: 'Đóng',
        // enable: '',
        // disable: ''
      },
      action: 'Thao tác',
      // add_settle_bet_info: '',
      agent: 'Đại lý',
      agent_account: 'Tài khoản đại lý',
      agent_count: 'Số đại lý',
      arena: 'Sân đấu',
      arena_suspend: 'Trận đấu tạm dừng',
      all: 'Toàn bộ',
      amount: 'Số tiền',
      announcement: 'Thông báo',
      average_percentage: 'Trung bình mỗi trận đấu',
      bet_amount: 'Số tiền đặt cược',
      bet_count: 'Số lần đặt cược',
      bet_type_list: {
        all: 'Toàn bộ',
        deposit: 'Nạp tiền',
        withdraw: 'Rút tiền',
        OrderDeposit: 'Nạp tiền',
        OrderWithdraw: 'Rút tiền',
        bet: 'Đặt cược',
        settle: 'Kết toán',
        cancel: 'Hủy bỏ',
        resettle: 'Kết toán lại',
        fail: 'Thất bại',
        init: 'Ban đầu',
      },
      // business_status: '',
      // calculation_base: '',
      // callback_url: '',
      change_password: 'Thay đổi mật mã',
      confirm: 'Xác nhận',
      confirm_password: 'Xác nhận mật khẩu',
      create_agent: 'Tạo đại lý mới',
      // create_setting: '',
      created_at: 'Thiết lập thời gian',
      currency: 'Loại tiền',
      // current_points: '',
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: 'Thời gian',
      date_type: 'Chọn thời gian',
      date_type_list: {
        BetAt: 'Thời gian cá cược',
        GameDate: 'Thời gian thi đấu',
        SettleAt: 'Thời gian kết toán',
        OpenRound: 'Thời gian bắt đầu',
      },
      declare_fight_winner: 'Công bố bên chiến thắng là',
      defaultLanguage: 'Ngôn ngữ mặc định',
      description: 'Miêu tả',
      email: 'E-mail',
      fight_count: 'Số trận đấu',
      fight_id: 'Số hiệu trận đấu',
      game_date: 'Thời gian trận đấu',
      // game_type: '',
      // ggr: '',
      ip: 'Địa chỉ IP',
      id: 'Số hiệu',
      kill_rate: 'Tỉ lệ giết',
      language: 'Ngôn ngữ',
      last_login: 'Lần đăng nhập cuối',
      // link_master: "",
      lock_earning: 'Chia thưởng',
      log: 'Đăng nhập',
      // main_arena: '',
      // main_pool: '',
      manager: 'Quản lý',
      master: 'Tổng đại lý',
      max: 'Giá trị lớn nhất',
      merchant: 'Doanh nghiệp',
      merchant_account: 'Tài khoản doanh nghiệp',
      meron_wala: 'Phượng Rồng',
      min: 'Giá trị nhỏ nhất',
      month: 'Tháng',
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: 'Tên',
      odd: 'Tỷ lệ cược',
      // none: '',
      // odds_type: "",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: 'Thao tác',
      // options: '',
      // order_num: "",
      order_status_list: {
        init: 'Nhận được đơn hàng',
        process: 'Đang xử lý',
        success: 'Thành công',
        failed: 'Thất bại',
        pending: 'Đang giải quyết',
        fail: 'Thất bại',
        error: 'Lỗi',
      },
      password: 'Mật khẩu',
      payout: 'Tỷ lệ cược',
      platform_profit: 'Lợi nhuận sàn',
      player_account: 'Tài khoản người chơi',
      // player_name: '',
      player_bet_limit: 'Giới hạn đặt cược mỗi lần của người chơi',
      player_count: 'Số người chơi',
      player_round_limit: 'Giới hạn đặt cược vòng của người chơi',
      // pool_status: '',
      profile: 'Dữ liệu đại lý',
      provider_name: 'Tên nhà cung cấp',
      rate: 'Hoa hồng (%)',
      rate_amount: 'Tiền hoa hồng',
      risk_odd: 'Tỷ lệ cược trò chơi',
      role: 'Vai trò',
      // risk_control: '',
      // risk_type_list:{
      //   bet_limit: '',
      // no_login: '',
      // },
      round_id: 'Số hiệu trận đấu',
      round_limit: 'Giới hạn vòng cược',
      // round_status: '',
      round_status_list: {
        idle: 'Giải lao',
        open: 'có thể đặt cược',
        close: 'Không được phép đặt cược',
        locked: 'Đã thanh toán',
        last_call: 'Đặt cược cuối cùng',
        // arena_close: "",
        unsettled: 'Chưa Giải Quyết',
        settled: 'Đã Giải Quyết',
      },
      status: 'Trạng thái',
      // setting: '',
      settled_at: 'Thời gian kết toán',
      // settle_zero: '',
      side: 'Đặt cược vào',
      // skin: '',
      // skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      // },
      slug: 'Mã đơn',
      theme: 'Chủ đề',
      // top_agent: '',
      total: 'Tổng',
      total_bet_amount: 'Tổng số tiền đặt cược',
      total_bet_count: 'Tổng số lần đặt cược',
      total_platform_profit: 'Tổng lợi nhuận của sàn',
      total_rate_amount: 'Tổng số tiền hoa hồng',
      total_user_bet: 'Tổng số người đặt cược',
      total_valid_bet_amount: 'Tổng đặt cược hiệu quả',
      type: 'Loại',
      // update_setting: '',
      updated_at: 'Cập nhật thời gian',
      usable_language: 'Ngôn ngữ có sẵn',
      user: 'Người sử dụng',
      user_count: 'Số lượng thành viên cá cược',
      video_url: 'Link liên kết của video',
      // icp_video_url: "",
      valid_amount: 'Đặt cược hợp lệ',
      winner: 'Kết quả',
    },
    times: {
      unset: 'Chưa thiết lập',
      today: 'Hôm nay',
      yesterday: 'Hôm qua',
      this_week: 'Tuần này',
      last_week: 'Tuần trước',
      this_month: 'Tháng này',
      last_month: 'Tháng trước',
      custom: 'Tự thiết lập',
      tooltip: 'Lưu trữ hồ sơ: 3 tháng (Từ ngày hôm nay - 100 ngày)',
    },
    menu: {
      group: {
        player: 'Người chơi',
        record: 'Báo cáo',
        manager: 'Quản lý',
        control: 'Bảng điều khiển',
        Agent: 'Quản lý đại lý',
        MasterAgent: 'Quản lý đại lý tổng',
        ArenaModerator: 'Chọi gà',
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: 'Bảng điều khiển',
      CurrentArena: 'Đặt cược hiện tại',
      Players: 'Quản lý người chơi',
      PlayerCredits: 'Lịch sử hạn mức người chơi',
      BetLog: 'Lịch sử đặt cược',
      GameResults: 'Kết quả trò chơi',
      Order: 'Lịch sử chuyển khoản',
      AgentList: 'Danh sách đại lý',
      AgentSource: 'Liên kết đại lý chính',
      AdminAgents: 'Quản lý đại lý',
      AgentShow: 'Quản lý dữ liệu',
      AgentRoles: 'Quyền hạn đại lý',
      AgentSubAccount: 'Quản trị viên',
      MasterAgentList: 'Danh sách đại lý tổng',
      MasterAgentSource: 'Liên kết đại lý chính',
      Merchants: 'Quản lý doanh nghiệp',
      MerchantRoles: 'Vai trò quản trị doanh nghiệp',
      MerchantAdmin: 'Quản lý tài khoản doanh nghiệp',
      MerchantIpList: 'Cài đặt hạn chế IP',
      ChangePassword: 'Thay đổi mật mã',
      GGR: 'Báo cáo GGR',
      Profile: 'Dữ liệu người dùng',
      VideoSource: 'Nguồn video',
      Transfer: 'Tra lệnh giao dịch',
      MonthlyProfit: 'Bảng tổng lợi nhuận tháng',
      // DailyBettingStatistic: "",
      ExchangeRate: 'Quản lý tỷ giá',
      CreateAgent: 'Tạo đại lý mới',
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: 'Vui lòng nhập {value}.',
      select_value: 'Vui lòng chọn {value}.',
      must_be_at_4_characters: '{value} Nhập ít nhất 4 ký tự',
      must_be_at_6_characters: '{value} Nhập ít nhất 6 ký tự',
      must_be_between_characters: '{value} Phải thuộc khoảng từ {min} đến {max} ký tự',
      must_be_between_number: '{value} phải nằm trong khoảng từ {min} đến {max}',
      must_be_alphanumeric: '{value} Nhập chữ cái tiếng anh hoặc số',
      must_be_number: 'Phải là một số',
      must_be_number_value: '{value} phải là một số',
      two_no_match: 'Dữ liệu không trùng khớp',
      failed_to_create: '{value} Tạo thất bại',
      failed_to_edit: '{value} Chỉnh sửa thất bại',
      success_to_create: '{value} Tạo thành công',
      success_to_edit: '{value}Chỉnh sửa thành công',
      success_to_change: '{value} Sửa đổi thành công',
      failed_to_update: '{value} Làm mới thất bại',
      decision_on_hold: 'Bấm vào nút trên, trận đấu chọi gà sẽ tạm dừng mọi chức năng cá cược và chuyển hồ sơ',
      cancel_on_hold: 'Bấm vào nút trên để bắt đầu vào chơi chọi gà',
      close_bet: 'Bấm vào nút bên trên, trận chọi gà sẽ tạm ngưng chức năng cá cược',
      open_bet: 'Bấm vào nút bên trên, trận chọi gà sẽ mở chức năng cá cược',
      mask_video: 'Nhấp vào nút bên trên, sàn sẽ bao phủ khối mặt nạ video',
      cancel_mask_video: 'Nhấp vào nút ở trên, sàn sẽ đóng khối mặt nạ video',
      delete: 'Xóa {value}？',
      choose_arena: 'Chọn trận đấu chọi gà',
      enter_fight_number: 'Nhập số hiệu trận đấu',
      select_date: 'Vui lòng chọn ngày',
      select_one: 'Vui lòng chọn ít nhất một {value}',
      browser_not_supported: 'Trình duyệt này không được hỗ trợ. Vui lòng nhấn nút OK để mở google chrome hoặc safari.',
      network_error_title: 'Lỗi mạng',
      network_error_info: 'Vui lòng xác minh mạng của bạn và làm mới trang',
      must_be_greater_than_zero: 'Số tiền phải lớn hơn hoặc bằng 0',
      must_be_greater_than_min: 'Số tiền tối đa phải lớn hơn hoặc bằng số tiền tối thiểu',
      incorrect_ip: 'Định dạng IP không chính xác',
      criteria_be_selected: 'Bắt buộc phải chọn 1 điều kiện tìm kiếm',
      success_to_delete: 'Đã xóa thành công {value}',
      failed_to_delete: 'Xóa {value} không thành công',
      // failed_to_delete_with_reason: "",
      account_placeholder: 'Vui lòng nhập 4-16 chữ cái tiếng anh, số',
      password_placeholder: 'Vui lòng nhập 6-20 chữ cái tiếng anh, số, ký tự',
      confirm_password_placeholder: 'Vui lòng nhập lại mật khẩu',
      date_type_hint:
        'Báo cáo dựa trên "{dateType}", 10:00 sáng (GMT+8) mỗi ngày sẽ tính dữ liệu của ngày hôm trước, không bao gồm dữ liệu của ngày hôm nay.',
      please_create_role: 'Trước khi thiết lập quản trị viên, vui lòng thiết lập vai trò',
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: 'Thiết lập giá trị đặt cược tối thiểu của người chơi',
      setting_player_bet_max: 'Thiết lập giá trị đặt cược tối đa của người chơi',
      // A_greater_tham_B: '',
      // reset_data: ''
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    },
  },
  AgentsPage: {
    master_account: 'Tài khoản tổng đại lý',
    languageList: 'Danh sách ngôn ngữ',
    create_master_agent: 'Tạo tổng đại lý mới',
    rate: 'Hoa hồng (%)',
    rate_note:
      'Nếu hoa hồng của đại lý tuyến dưới cao hơn hoa hồng của đại lý tuyến trên, hệ thống sẽ tự động giảm thành tỷ lệ hoa hồng mới.',
    max_with_value: 'Giá trị tối đa: {value}',
    abbr: 'Viết tắt',
    abbr_placeholder: 'Vui lòng nhập 1-6 chữ và số',
    edit_agent: 'Chỉnh sửa đại lý',
    // agent_credit_points_options: '',
  },
  AgentSubAccountPage: {
    authority: 'Quyền hạn',
    authority_list: {
      read: 'Đọc',
      editor: 'Chỉnh sửa',
    },
    create_sub_account: 'Tạo quản trị viên',
  },
  BetLogPage: {
    win_lose: 'Thắng thua',
    bet_return: 'Số tiền hoàn trả',
    is_settled: 'Đã thanh toán chưa',
    device: 'Thiết bị',
    amount_type: 'Loại số tiền',
    amount_range: 'Phạm vi số tiền',
  },
  ChangePasswordPage: {
    current_password: 'Mật khẩu hiện tại',
    current_password_placeholder: 'Vui lòng nhập mật khẩu hiện tại',
    new_password: 'Mật khẩu mới',
  },
  ArenaModeratorPage: {
    video_rate: 'Tỉ lệ video',
    online_users: 'Số người online',
  },
  ControlPanelPage: {
    arena_on: 'Mở',
    arena_off: 'Đóng',
    no_announce: 'Chưa có thông báo, vui lòng nhấp vào chỉnh sửa để thêm',
    edit_announce: 'Chỉnh sửa thông báo',
    open_arena_title: 'Trận đấu chọi gà đã bắt đầu chưa？',
    close_arena_title: 'Bạn có chắc chắn muốn kết thúc trận chọi gà hôm nay (tất cả các trận đấu sẽ kết thúc)?',
    start_fight: 'Bắt đầu mã trận',
    start_time: 'Thời gian bắt đầu',
    open_betting: 'Đồng ý đặt cược?',
    close_betting: 'Đóng đặt cược?',
    re_declare_title: 'Công bố lại bên chiến thắng?',
    re_declare_content: 'Công bố lại bên chiến thắng, bên chiến thắng mã trận đấu #{id} là',
    lock_earing_title: 'Chia thưởng?',
    lock_earing_content: 'Chia thưởng, mã trận đấu #{id}',
    click_again_to: 'Bấm vào để',
    file_upload: 'Tải lên dữ liệu',
    arena_cover: 'Bản đồ lối vào trò chơi',
    mask_file: 'Bản đồ mạng che',
    schedule: 'Lịch thi đấu',
    // edit_provider_url: "",
    img_path: 'Đường dẫn tập tin',
    below_px_value: 'Dưới {value}px',
    img_format: 'Định dạng',
    img_recommended_size: 'Kích thước đề xuất',
    file_size: 'Kích thước tập tin',
    image: 'Hình ảnh',
    file_no_selected: 'Không có tệp nào được chọn',
    no_image: 'Không có hình ảnh',
    // edit_video_url: "",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: 'Vui lòng nhập số trận đấu',
      max_image_size: 'Kích thước hình ảnh tối đa là {value}kb',
      error_image_type: 'Định dạng hình ảnh sai, chỉ chấp nhận: jpg, jpeg, png',
      delete_image_placeholder: 'Bạn có chắc chắn muốn xóa {value} không?',
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    },
  },
  CurrentArenaPage: {
    count: 'Số lượng',
    unsettlement: 'Chưa thanh toán',
    fight_action: 'Vận hành trận đấu',
  },
  GameResultsPage: {
    open_bet_at: 'Thời gian giao dịch bắt đầu',
    close_bet_at: 'Thời gian đặt cược kết thúc',
    declare_at: 'Thời gian thông báo kết quả',
    reload_at: 'Thiết lập lại thời gian',
    cancel_at: 'Thời gian hủy bỏ',
    total_rate: 'Tổng hoa hồng',
    total_meron: 'Meron - tổng đặt cược',
    total_wala: 'Wala - tổng đặt cược',
    total_draw: 'Draw - Tổng đặt cược',
    status_list: {
      initial: 'Ban đầu',
      betting: 'Đang đặt cược',
      fighting: 'Đang chọi gà',
      finished: 'Đang kết toán',
      reloaded: 'Phán xét lại',
      earn_locked: 'Chia thưởng',
      last_call: 'Đặt cược cuối cùng',
      cancel: 'Hủy bỏ',
    },
    time: 'Thời gian',
    // reason: "",
    cancel_reasons: {
      // '3000': "",
      // '3001': "",
      // canceled_manual: "",
      // canceled_ws: ""
    },
    open_round: 'Thời gian bắt đầu',
  },
  LoginPage: {
    log_in: 'Đăng nhập',
    otp: 'Mật mã OTP',
    message: {
      success: 'Đăng nhập thành công',
      failed: 'Tài khoản hoặc mật khẩu sai',
    },
  },
  MerchantsPage: {
    admin: 'Quản lý quản trị viên',
    role: 'Vai trò quản lý',
    create_merchant: 'Tạo doanh nghiệp mới',
    name: 'Tên doanh nghiệp',
    message: {
      // master_agent_only_select_one: ""
    },
  },
  MerchantRolesPage: {
    current_arena: 'Current Arena',
    can_permission_pages: 'Các trang có sẵn',
    use_account: 'Sử dụng tài khoản',
    create_role: 'Thiết lập vai trò',
    edit_role: 'Chỉnh sửa vai trò',
    role_name: 'Tên vai trò',
    color: 'Màu sắc',
    can_use_pages: 'Các trang có sẵn',
    arena_on_off: 'Đóng/mở trận đấu chọi gà',
    fight_control: 'Điều khiển trận đấu',
    lock_earning: 'Thanh toán',
    announcement: 'Thông báo',
    decision_on_hold: 'Dừng trận đấu',
    monitor: 'Giám sát',
    no_bets_allowed: 'Không được phép cược',
    mask_video: 'Mặt nạ video',
    read: 'Đọc',
    update: 'Làm mới',
    create: 'Thiết lập',
    delete: 'Xóa',
    edit: 'Chỉnh sửa',
    dashboard_card: 'Hiển thị tổng số tiền',
    confirm: 'Xác nhận',
    approve: 'Phê duyệt',
    arena_cover: 'Bản đồ lối vào trò chơi',
    mask_file: 'Bản đồ mạng che',
    schedule: 'Lịch thi đấu',
    export: 'Xuất khẩu',
    // edit_provider_url: "",
    // display_rate: "",
    // view_schedule: '',
    // view_log: '',
    account_to_use: 'Vẫn có tài khoản sử dụng vai trò này',
    // risk_edit: '',
    monitor_video: 'Giám sát video',
  },
  MerchantAdminPage: {
    edit_manager: 'Chỉnh sửa quản trị viên',
    create_manager: 'Thiết lập quản trị viên',
  },
  MerchantIpPage: {
    master_agent: 'Tổng đại lý hoặc đại lý',
    create_ip: 'Thêm địa chỉ IP',
  },
  DailyBettingStatisticPage: {
    // yesterday_percentage: "",
    // last_week_percentage: "",
    player_count: 'Số lượng thành viên cá cược',
    // ave_per_fight_percentage: "",
    // last_week_ave_per_fight_percentage: ""
  },
  OrderPage: {
    after_balance: 'Số dư sau khi chuyển',
  },
  PlayersPage: {
    balance: 'Số dư',
    fee: 'Phí xử lý',
    current_sign_in_ip: 'Đăng nhập IP',
    total_deposit: 'Tổng tiền nạp',
    total_withdraw: 'Tổng tiền rút',
    total_win_lose: 'Tổng thắng thua',
  },
  PlayerCreditsPage: {
    id: 'Số giao dịch vòng cược',
    type: {
      deposit: 'Nạp tiền',
      withdraw: 'Rút tiền',
      settle: 'Thắng',
      cancel: 'Hủy bỏ',
      bet: 'Đặt cược',
    },
    before_amount: 'Số tiền trước khi thao tác',
    after_amount: 'Số tiền sau khi thao tác',
  },
  ProfilePage: {
    // setting_round_draw_max: "Thiết lập số tiền đặt cược tố đa của Draw",
    setting_round_draw_max_part1: 'Thiết lập số tiền đặt cược tố đa là',
    setting_round_draw_max_part2: 'Trong một vòng',
    player_limit: 'Giới hạn đặt cược của người chơi',
    setting_player_max: 'Thiết lập giá trị đặt cược lớn nhất, nhỏ nhất',
    basic_info_title: 'Thông tin cơ bản',
    basic_info_text: 'Thông tin cơ bản về đại lý',
    // integration_info: '',
    // auth: '',
    // secret: '',
    // api_doc_url: '',
    // api_url: '',
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
    // please_decrease_own_points_to_zero: '',
    // please_remove_all_designated_agents: '',
  },
  VideoSourcePage: {
    source: 'Nguồn video',
    agent_source: 'Nguồn video tổng đại lý',
    accessable_merchants: 'Các doanh nghiệp có sẵn',
  },
  TransferPage: {
    slug: 'Số giao dịch vòng cược',
    data: 'Số liệu',
    res: 'Trả lời',
    round_id: 'Vòng ID',
    setting_master_id: 'Cài đặt',
    num: 'Mã đơn',
    resend: 'Gửi lại',
    bet_num: 'Mã đơn',
    resend_note: 'Ghi chú',
    resend_mark: 'Đánh dấu',
    message: {
      success_to_resend: 'Truyền lại thành công, Không:{value}',
      fail_to_resend: 'Truyền lại không thành công, Không:{value}',
      success_to_remark: 'Ghi chú thành công, Không:{value}',
      fail_to_remark: 'Ghi chú không thành công, Không:{value}',
    },
  },
  ExchangeRatePage: {
    currency: 'ID tiền tệ',
    currency_name: 'Tên tiền tệ',
    last_approved_rate: 'Tỷ giá trước đó',
    // today_rate: "",
    ref_rate: 'Tham khảo tỷ giá',
    ref_rate_time: 'Thời gian tham khảo tỷ giá',
    tbd_confirm_rate: 'Xác nhận tỷ giá',
    tbd_modify_rate: 'Thay đổi tỷ giá',
    record: 'Lịch sử biến động',
    current_time: 'Thời gian hiện tại',
    last_approved_time: 'Thời gian phê duyệt cuối cùng',
    base_currency: 'Tiền tệ cơ bản',
    fetch_exchange_rate: 'Nhận tỷ giá',
    approve_rate: 'Tỷ giá được phê duyệt',
    rate_warning: '＊Xin lưu ý: Tỷ giá sẽ có hiệu lực ngay sau khi được phê duyệt và xác nhận!',
    log: 'Lịch sử biến động tỷ giá',
    confirmed_by: 'Xác nhận nhân viên',
    confirmed_time: 'Xác nhận thời gian',
    approved_by: 'Nhân viên xét duyệt',
    approved_time: 'Phê duyệt thời gian',
    exchange_rate: 'Tỷ giá',
  },
  CreateAgentPage: {
    // parent_agent: '',
    // environment: '',
    // staging: '',
    // production: '',
    // brand: '',
    // trade_mode: '',
    // transfer_wallet: '',
    // options: '',
    // single_wallet: '',
    // level_sub_agent_with_value: '',
    // delete_agent_with_value: '',
    // add_agent_with_value: '',
    // agent_info: '',
    // designated_agent: '',
    message: {
      // select_master_first: '',
      // agent_same_master: '',
      // please_refresh_cache: '',
      // log_saved_time: '',
      // success_refresh_cache: '',
      // fail_refresh_cache: '',
    },
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: '',
    // change_agent: '',
    // view_complete_record: '',
  },
}
