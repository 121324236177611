import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'

export default {
  register() {
    Vue.filter('formatTimes', function (value) {
      if (!value) return '-'
      return moment(value).utc().format('YYYY-MM-DD HH:mm:ss')
    })

    Vue.filter('formatDay', function (value) {
      if (!value) return '-'
      return moment(value).utc().format('YYYY-MM-DD')
    })

    Vue.filter('formatMonthly', function (value) {
      if (!value) return ''
      return moment(value).utc().format('YYYY-MM')
    })

    Vue.filter('formatString', function (value) {
      if (!value) return '-'
      return value
    })

    Vue.filter('formatNumber', function (value) {
      if (!value) return 0
      return numeral(value).format('0,0')
    })

    Vue.filter('formatNumberWithPoint', function (value) {
      if (!value) return '0.00'
      return numeral(value).format('0,0.00')
    })

    Vue.filter('formatDayToUTC8', function (value) {
      if (!value) return '-'
      return moment(value).format('YYYY-MM-DD')
    })

    Vue.filter('formatMonthlyToUTC8', function (value) {
      if (!value) return ''
      return moment(value).format('YYYY-MM')
    })

    Vue.filter('formatTimesToUTC8', function (value) {
      if (!value) return '-'
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
  },
}
