import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import storage, { PREVIOUS_URL } from '@/storage'
import { getMenuPermissions } from '@/api'

Vue.use(VueRouter)

const authenticateWhenAuth = (to, from, next) => {
  const { name } = from
  if (name === to.name) {
    return next(false)
  }
  if (store.getters['auth/token'] !== undefined && store.getters['auth/token'] !== '') {
    next({ name: 'Home' })
  } else {
    next()
  }
}

const authenticate = async (to, from, next) => {
  if (from.name === to.name) {
    return next(false)
  }

  getMenuPermissions().then((data) => {
    const menus = data.data.menus
    if (to.name === 'Login' && menus[to.name] !== true) {
      // console.log(`Have Auth Token Can't Go to Login Page`);
      next({ name: 'Home' })
    } else {
      next()
    }
  })
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: authenticateWhenAuth,
    component: () => import('../pages/LoginPage.vue'),
  },
  {
    path: '/entry',
    name: 'Entry',
    //beforeEnter: authenticateWhenAuth,
    component: () => import('../pages/EntryPage.vue'),
  },
  {
    path: '/',
    component: () => import('../pages/LayoutPage.vue'),
    beforeEnter: authenticate,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../pages/HomePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../pages/profile/ProfilePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'video_source',
        name: 'VideoSource',
        component: () => import('../pages/video_source/VideoSourcePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'single_moderator',
        name: 'SingleModerator',
        component: () => import('../pages/arena_moderator/ArenaModeratorPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'single_moderator/panel',
        name: 'ControlPanel',
        component: () => import('../pages/arena_moderator/ControlPanelPage.vue'),
        meta: {
          keepAlive: false,
        },
        beforeEnter: (to, from, next) => {
          const { query } = to
          const { main_pool_id, merchant_provider_id } = query
          if (!main_pool_id || !merchant_provider_id) {
            next({ name: 'Login' })
          } else {
            next()
          }
        },
      },
      {
        path: 'multi_moderator',
        name: 'MultiModerator',
        component: () => import('../pages/arena_moderator/MultiModeratorPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'multi_moderator/current_arena',
        name: 'CurrentArena',
        component: () => import('../pages/arena_moderator/CurrentArenaPage.vue'),
        meta: {
          keepAlive: false,
        },
        beforeEnter: (to, from, next) => {
          const { query } = to
          const { merchant_provider_id } = query
          if (!merchant_provider_id) {
            next({ name: 'Login' })
          } else {
            next()
          }
        },
      },
      {
        path: 'players',
        name: 'Players',
        component: () => import('../pages/players/PlayersPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'player_credits',
        name: 'PlayerCredits',
        component: () => import('../pages/players/PlayerCreditsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../pages/players/OrderPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'admin_agents',
        name: 'AdminAgents',
        component: () => import('../pages/agents/AdminAgentsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/agent_list',
        name: 'AgentList',
        component: () => import('../pages/agents/AgentsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/source',
        name: 'AgentSource',
        component: () => import('../pages/agents/AgentSourcePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/agent_list/:id',
        name: 'AgentShow',
        component: () => import('../pages/agents/AgentShowPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent_sub_account',
        name: 'AgentSubAccount',
        component: () => import('../pages/agents/AgentSubAccountPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent_sub_account/:id',
        name: 'AdminAgentSubAccount',
        component: () => import('../pages/agents/AdminAgentSubAccountPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'create_agent',
        name: 'CreateAgent',
        component: () => import('../pages/agents/CreateAgentPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent_points_record',
        name: 'AgentPointsRecord',
        component: () => import('../pages/agents/AgentPointsRecordPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/agent_credit_points',
        name: 'AgentCreditPoints',
        component: () => import('../pages/agents/AgentCreditPointsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants',
        name: 'Merchants',
        component: () => import('../pages/merchants/MerchantsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants/:id/admin',
        name: 'AdminMerchantAdmin',
        component: () => import('../pages/merchants/AdminMerchantAdminPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants/:id/roles',
        name: 'AdminMerchantRoles',
        component: () => import('../pages/merchants/AdminMerchantRolesPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_roles',
        name: 'MerchantRoles',
        component: () => import('../pages/merchants/MerchantRolesPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_admin',
        name: 'MerchantAdmin',
        component: () => import('../pages/merchants/MerchantAdminPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_ip_list',
        name: 'MerchantIpList',
        component: () => import('../pages/merchants/MerchantIpListPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'bet_log',
        name: 'BetLog',
        component: () => import('../pages/records/BetLogPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'game_results',
        name: 'GameResults',
        component: () => import('../pages/records/GameResultsPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'win_lose',
        name: 'WinLose',
        component: () => import('../pages/records/WinLosePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'ggr',
        name: 'GGR',
        component: () => import('../pages/records/GGRPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      // {
      //   path: 'monthly_profit',
      //   name: 'MonthlyProfit',
      //   component: Pages.MonthlyProfitPage,
      //   meta: {
      //     keepAlive: false,
      //   },
      // },
      {
        path: 'daily_betting_statistic',
        name: 'DailyBettingStatistic',
        component: () => import('../pages/records/DailyBettingStatisticPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'transfer',
        name: 'Transfer',
        component: () => import('../pages/records/TransferPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'exchange_rate',
        name: 'ExchangeRate',
        component: () => import('../pages/records/ExchangeRatePage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'pool_setting',
        name: 'PoolSetting',
        component: () => import('../pages/pool/PoolSettingPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'malay_odds_setting',
        name: 'MalayOddsSetting',
        component: () => import('../pages/pool/MalayOddsSettingPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'change_password',
        name: 'ChangePassword',
        component: () => import('../pages/personal/ChangePasswordPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'error',
        name: 'Error',
        component: () => import('../pages/ErrorPage.vue'),
        meta: {
          keepAlive: false,
        },
      },
    ],
  },
  { path: '/404', name: 'NotFound', component: () => import('../pages/NotFoundPage.vue') },
  { path: '/:catchAll(.*)', redirect: { name: 'NotFound' } },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.afterEach((to) => {
  const { name, query, params } = to
  storage.setItem(PREVIOUS_URL, { name, query, params })
})

export default router
