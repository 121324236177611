import Vue from 'vue'
import Antd, { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { Plugin } from 'vue-fragment'
import i18n from './i18n'
import moment from 'moment'
import { tz } from 'moment-timezone'
import infiniteScroll from 'vue-infinite-scroll'
import router from './router'
import store from './store'
import ActionCableVue from 'actioncable-vue'
import App from './App.vue'
import filter from './tools/filter'

if (location.port) {
  document.title = 'FATPIT'
} else {
  const hostname = location.hostname
  const hostnameSplit = hostname.split('-')
  if (hostnameSplit.length === 1) {
    document.title = 'FATPIT'
  } else {
    const prefix = hostnameSplit[0]
    if (prefix) {
      document.title = prefix.toUpperCase()
    } else {
      document.title = prefix
    }
  }
}

Vue.config.productionTip = false
Vue.use(Plugin)
Vue.use(Antd)
Vue.use(infiniteScroll)
Vue.prototype.moment = moment
Vue.prototype.tz = tz
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  // connectionUrl: process.env.VUE_APP_CABLE_URL,
  connectImmediately: false,
})
filter.register()

message.config({
  duration: 5,
})

export const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
})

document.addEventListener('DOMContentLoaded', async () => {
  if (window.location.host.includes('fiesta')) {
    await import('./scss/application.scss')
  } else {
    await import('./scss/application_dark.scss')
  }

  app.$mount('#app')
})
